/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import '../index.scss';
import {graphql, Link} from 'gatsby';
import MadeWithLove from '../components/MadeWithLove/MadeWithLove';
import {useIsPastScroll} from '../hooks/useIsPastScroll';
import {c} from '../utils';
import Arrow from '../components/Arrow';
import {ComponentProps, Dict, Image} from '../types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Parallax} from 'react-scroll-parallax';
import {Helmet} from 'react-helmet';

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/party/"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: {cropFocus: CENTER}
          )
        }
        relativePath
      }
    }
  }`;

type PartyMember = {
  title: string;
  name: string;
  lastName: string;
  file: string;
  role: string;
}

const brookesParty: PartyMember[] = [
  { title: 'Ms.',  name: 'Jonalyn',  lastName: 'Trisolini',   role: 'Maid of Honor',  file: 'jona.jpg' },
  { title: 'Mrs.', name: 'Raquel',   lastName: 'Duque',       role: 'Matron of Honor',file: 'raquel.jpeg' },
  { title: 'Ms.',  name: 'Daniela',  lastName: 'Cantu',       role: 'Bridesmaid',     file: 'daniela.jpg' },
  { title: 'Ms.',  name: 'Maria',    lastName: 'Gomez',       role: 'Bridesmaid',     file: 'maria.jpg' },
  { title: 'Ms.',  name: 'Amy',      lastName: 'Fields',      role: 'Bridesmaid',     file: 'amy.jpeg' },
  { title: 'Ms.',  name: 'Romina',   lastName: 'Ghelich-Khan',role: 'Bridesmaid',     file: 'romina.jpeg' },
  { title: 'Ms.',  name: 'Meredith', lastName: 'Montesantos', role: 'Bridesmaid',     file: 'meredith.jpg' },
  { title: 'Ms.',  name: 'Jenn',     lastName: 'O\'Hagan',    role: 'Bridesmaid',     file: 'jenn.jpg' },
  { title: 'Ms.',  name: 'Paty',     lastName: 'Cantu',       role: 'Bridesmaid',     file: 'paty.jpeg' },
  { title: 'Ms.',  name: 'Vanessa',  lastName: 'Morales',     role: 'Bridesmaid',     file: 'vanessa.jpg' },
];
const vivianosParty: PartyMember[] = [
  { title: 'Mr.',  name: 'Sebastian',lastName: 'Cantu',       role: 'Best Man',       file: 'sebastian.jpg' },
  { title: 'Mr.',  name: 'Robert',   lastName: 'Rapp',        role: 'Best Man',       file: 'robert.jpeg' },
  { title: 'Mr.',  name: 'Rodolfo',  lastName: 'Cañamar',     role: 'Groomsman',      file: 'rodolfo.jpg' },
  { title: 'Mr.',  name: 'Nilton',   lastName: 'Duque',       role: 'Groomsman',      file: 'nilton.jpeg' },
  { title: 'Mr.',  name: 'Luis',     lastName: 'Roberto',     role: 'Groomsman',      file: 'luis.jpg' },
  { title: 'Mr.',  name: 'Ryan',     lastName: 'Levy',        role: 'Groomsman',      file: 'ryan.jpg' },
  { title: 'Mr.',  name: 'Zach',     lastName: 'Wajda',       role: 'Groomsman',      file: 'zach.jpg' },
  { title: 'Mr.',  name: 'Jeremy',   lastName: 'Norton',      role: 'Groomsman',      file: 'jeremy.jpg' },
  { title: 'Mr.',  name: 'Colby',    lastName: 'Camp',        role: 'Groomsman',      file: 'colby.jpeg' },
  { title: 'Mr.',  name: 'Ryan',     lastName: 'Bender',      role: 'Groomsman',      file: 'ryan-b.jpeg' },
];

type MemberCardProps = PartyMember & ComponentProps;
const MemberCard = ({ title, name, lastName, file, role, children, ...props}: MemberCardProps) => {
  return <div>
    {children}
    <h3>{name} | {role}</h3>
  </div>;
};

type WeddingPartyData = {
  data: {
    allFile: {
      nodes: Image[];
    }
  }
}
const WeddingParty = ({ data }: WeddingPartyData) => {
  const scrolled = useIsPastScroll();
  const images: Dict<Image> = {};
  data?.allFile?.nodes?.forEach((img: Image) => {
    images[img.relativePath] = img;
  });

  return (
    <main className='faqs'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wedding Party | The Cantú's</title>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://thecantus.com"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "FAQs",
                "item": "https://thecantus.com/wedding-party"
              }]
            }
         `}
        </script>
      </Helmet>

      <Link to={'/#more-info'} className={c('back-arrow', scrolled && 'shadow')}>
        <Arrow /> BACK
      </Link>

      <h1>Wedding Party</h1>

      <hr />

      <div className='wedding-party'>
        {[brookesParty, vivianosParty].map((party, c) => (
          <div key={c}>
            {party.map((member, r) => (
              <Parallax
                key={r}
                translateY={[-5 * (c + 1 * (r % 3)), 5 * (c + 1 * (r % 3)), 'easeInQuad']}>
                <MemberCard {...member} key={r}>
                  {/* @ts-ignore */}
                  <GatsbyImage image={getImage(images[`party/${member.file}`])!}
                    alt='image' />
                </MemberCard>
              </Parallax>
            ))}
          </div>
        ))}
      </div>

      <MadeWithLove style={{ marginTop: '20vh'}}/>

    </main>
  );
};
export default WeddingParty;
